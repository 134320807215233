import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  color,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";

export default function SubstackModal2() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();

  const buttonText = colorMode === "light" ? "text-black" : "text-white";
  const backgroundColor = colorMode === "light" ? "white" : "dark.100";

  // const buttonBG = colorMode === "light" ? "dark:bg-black" : ;
  const buttonBG = colorMode === "light" ? "bg-white" : "bg-black";

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}
      <Center>
        <Center>
          <button
            onClick={onOpen}
            className="mt-7 mb-5 rounded-2xl p-px bg-[linear-gradient(to_right,theme(colors.fuchsia.900),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.fuchsia.300),theme(colors.fuchsia.700),theme(colors.fuchsia.800),theme(colors.fuchsia.900))] bg-[length:400%_auto] animate-gradient"
          >
            <div
              className={`rounded-2xl p-3 w-100 ${buttonBG} font-semibold ${buttonText}`}
            >
              Join The Collector{`'`}s Club
            </div>
          </button>
        </Center>
        {/* <InfoIcon ml={3} mb={1} boxSize={3} onClick={onOpen} color={color} /> */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={backgroundColor}>
            {/* <ModalHeader>Proof Of Fan</ModalHeader> */}
            <ModalCloseButton style={{ color: "black", marginRight: 20, marginTop: 5 }}/>
            <ModalBody>
              <Center>
                <iframe
                  src="https://songcards.substack.com/embed"
                  width="100%"
                  height={320}
                  style={{ border: "1px solid #EEE", background: "white" }}
                  // frameBorder={0}
                  // scrolling="no"
                ></iframe>
              </Center>
              {/* If you have any questions about PoF or Songcards in general, read the FAQ, get in touch either via email, or join our community.
                        <br />
                        <br /> */}
            </ModalBody>

            {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter> */}
          </ModalContent>
        </Modal>
      </Center>
    </>
  );
}
