import FeaturedCards from "@/components/FeaturedCards";
import SubstackModal2 from "@/components/SubstackModal2";
import {
  Center,
  Container,
  Divider,
  Heading,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import Head from "next/head";
import Link from "next/link";

export default function Discover() {
  const { colorMode } = useColorMode();

  return (
    <>
      <Head>
        <title>Songcards: Listen | Discover | Collect</title>
        <link rel="icon" href="/SC Icon_A_Light.svg" />
        <meta
          name="description"
          content="Discover new music and grow your collection"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          itemProp="image"
          content="https://beta.songcards.io/sc-pink.png"
        />
        <link rel="canonical" href={`https://beta.songcards.io/`} />
        <link rel="apple-touch-icon" href="/SC Icon_A_Light.svg" />
        <meta
          property="og:image"
          content="https://beta.songcards.io/sc-pink.png"
        />
        <meta property="og:url" content="https://beta.songcards.io/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Songcards: Listen | Discover | Collect"
        />
        <meta
          property="og:description"
          content="Discover new music and grow your collection"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Songcards: Listen | Discover | Collect"
        />
        <meta
          name="twitter:description"
          content="Discover new music and grow your collection"
        />
        <meta
          name="twitter:image"
          content="https://beta.songcards.io/sc-pink.png"
        />
      </Head>
      <>
        <Center>
          <Heading
            as="h1"
            size="2xl"
            letterSpacing={4}
            textAlign="center"
            maxW="sm"
            mb={5}
            mt={0}
          >
            LISTEN. DISCOVER.
            <div className="mr-2 ml-2 bg-[linear-gradient(to_right,theme(colors.fuchsia.900),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.fuchsia.300),theme(colors.fuchsia.700),theme(colors.fuchsia.800),theme(colors.fuchsia.900))] inline-block text-transparent bg-clip-text bg-[length:400%_auto] animate-gradient">
              COLLECT.
            </div>
          </Heading>
        </Center>
        <SubstackModal2 />
        <Center mt={4}>
          <Divider mb={10} mt={3} maxW={620} />
        </Center>
        <Container maxWidth="2xl" mb={20}>
          <Center>
            <Heading size="lg">Discover Songcards</Heading>
          </Center>
          <br />
          <Center mb={5}>
            <Text>and grow your collection</Text>
          </Center>
          <FeaturedCards />
        </Container>
        <Center mb={300}>
          <Link href={`/`}>
            <Image
              src={
                colorMode === "dark"
                  ? "/SC Icon_B_White.svg"
                  : "/SC Icon_B_Black.svg"
              }
              width={8}
              alt="Discover more Songcards"
            />
          </Link>
        </Center>
      </>
    </>
  );
}
